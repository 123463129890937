@use "../styles/theme.scss";

:local(.modal) {
  position: relative;
  display: flex;
  flex-direction: column;
  height: max-content;
  background-color: theme.$background1-color;
  border: 1px solid theme.$border1-color;
  border-radius: theme.$border-radius-regular;
  margin: 24px;
  z-index: 1;
  width: 100%;
  max-width: 460px;
}

:local(.header) {
  display: flex;
  height: 48px;
  border-bottom: 1px solid theme.$border1-color;
  align-items: center;
  justify-content: center;
}

:local(.before-title) {
  position: absolute;
  left: 0;
  margin-left: 16px;
}

:local(.after-title) {
  position: absolute;
  right: 0;
  margin-right: 16px;
}

:local(.content) {
  min-height: 200px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

:local(.sm-fullscreen) {
  @media(max-width: theme.$breakpoint-lg - 1), (max-height: theme.$breakpoint-vr - 1) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-width: 0;
    border-radius: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: none;

    :local(.content) {
      overflow-y: auto;
    }
  }
}